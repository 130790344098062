const makeTime = (time = new Date()) => new Date(time).toISOString().replace(/[-:]|\.\d{3}/g, '');

const makeUrl = (base, query) =>
  Object.keys(query).reduce((accum, key, index) => {
    const value = query[key];

    if (value !== null) {
      return `${accum}${index === 0 ? '?' : '&'}${key}=${encodeURIComponent(value)}`;
    }
    return accum;
  }, base);

const dayNameList = {
  sun: 'SU',
  mon: 'MO',
  tue: 'TU',
  wed: 'WE',
  thu: 'TH',
  fri: 'FR',
  sat: 'SA',
};

const createScheduleString = schedule => {
  let daysString = '';

  schedule.forEach(i => {
    daysString += daysString.length ? `,${dayNameList[i]}` : dayNameList[i];
  });

  return `RRULE:FREQ=WEEKLY;BYDAY=${daysString}`;
};

const makeGoogleCalendarUrl = event =>
  makeUrl('https://calendar.google.com/calendar/render', {
    action: 'TEMPLATE',
    dates: `${makeTime(event.startsAt)}/${makeTime(event.endsAt)}`,
    text: event.name,
    details: event.details,
    recur: createScheduleString(event.schedule),
  });

const makeICSCalendarUrl = event => {
  const components = [
    'BEGIN:VCALENDAR',
    'PRODID:GMB',
    'VERSION:2.0',
    'BEGIN:VEVENT',
    'UID:info@gmb.io',
    createScheduleString(event.schedule),
    `DTSTAMP:${makeTime(event.startsAt)}`,
    `URL:${document.URL}`,
    `DTSTART:${makeTime(event.startsAt)}`,
    `DTEND:${makeTime(event.endsAt)}`,
    `SUMMARY:${event.name}`,
    `DESCRIPTION:${event.details}`,
    'END:VEVENT',
    'END:VCALENDAR',
  ];

  return encodeURI(`data:text/calendar;charset=utf8,${components.join('\n')}`);
};

const makeCalendarUrls = {
  google: event => makeGoogleCalendarUrl(event),
  ics: event => makeICSCalendarUrl(event),
};

export default makeCalendarUrls;
