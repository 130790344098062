import React, { memo, useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import getConfig from 'next/config';
import Text from 'components/core/Text';
import makeCalendarUrls from '../../utils/makeCalendarUrls';
import { Wrapper, ListItem, Arrow, List, Button, Icon } from './styles';

const { publicRuntimeConfig } = getConfig();
const { baseUrl } = publicRuntimeConfig;

const list = [
  {
    id: 'google',
    title: 'Add to Google calendar',
  },
  {
    id: 'ics',
    title: 'Add to Apple calendar',
  },
];

function generateEventDetails({ programName, programId }) {
  return `\n<b>You're scheduled for a session of ${programName} today</b>.
<a href="${baseUrl}/programs/${programId}">Start It Here</a>`;
}

function ButtonAddToCalendar({ program, schedule }) {
  const [isListOpen, setIsListOpen] = useState(false);
  const close = useCallback(() => {
    setIsListOpen(false);
  }, []);

  if (typeof window !== 'undefined') {
    if (isListOpen) {
      window.addEventListener('click', close);
    } else {
      window.removeEventListener('click', close);
    }
  }

  useEffect(() => {
    return () => {
      window.removeEventListener('click', close);
    };
  }, [close]);

  const toggleList = useCallback(() => {
    // using setTimeout instead of stopPropagation helps to prevent close
    // dropdown list immediately after open and close another opened dropdown lists
    // on a page
    setTimeout(() => setIsListOpen(!isListOpen));
  }, [isListOpen]);

  const selectItem = item => {
    const momentDay = moment().minute(0);
    const startsAt = momentDay.hour(12).toISOString();
    const endsAt = momentDay.hour(13).toISOString();

    const url = makeCalendarUrls[item.id]({
      name: `${program.name}`,
      details: generateEventDetails({
        programName: program.name,
        programId: program.id,
      }),
      startsAt,
      endsAt,
      schedule,
    });

    if (item.id === 'ics') {
      const link = document.createElement('a');
      link.href = url;
      link.download = `${program.name}.ics`;
      link.click();
    } else {
      window.open(url, '_blank').focus();
    }

    close();
  };

  return (
    <Wrapper>
      <Button onClick={toggleList} title="Add to calendar">
        <Arrow arrowUp={isListOpen} />
      </Button>
      {isListOpen && (
        <List>
          {list.map(item => (
            <ListItem key={item.id} onClick={() => selectItem(item)}>
              <Text tag="span" p={1}>
                <Icon id={item.id} /> {item.title}
              </Text>
            </ListItem>
          ))}
        </List>
      )}
    </Wrapper>
  );
}

export default memo(ButtonAddToCalendar);
