import Loader from 'components/Loader';
import styled from 'styled-components';

const PageLoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
`;

const PageLoader = () => {
  return (
    <PageLoaderWrapper>
      <Loader />
    </PageLoaderWrapper>
  );
};

export default PageLoader;
