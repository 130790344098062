import styled from 'styled-components';
import colors from '../../constants/colors';
import arrowDownIcon from '../../assets/icons/arrow_down.svg';
import calendarIcon from '../../assets/icons/calendar_icon.svg';
import googleCalendarIcon from '../../assets/icons/google_calendar_icon.svg';
import appleIcon from '../../assets/icons/apple_icon.svg';
import outlookIcon from '../../assets/icons/outlook_icon.svg';
import yahooIcon from '../../assets/icons/yahoo_icon.svg';

const icons = {
  google: googleCalendarIcon,
  ics: appleIcon,
  outlook: outlookIcon,
  yahoo: yahooIcon,
};

const Wrapper = styled.div`
  position: relative;
  z-index: 10;
`;

const Button = styled.button`
  display: flex;
  justify-content: flex-end;
  height: 32px;
  width: 62px;
  background: url(${calendarIcon}) no-repeat;
  background-size: contain;
  background-position: 0 50%;
  cursor: pointer;
  padding: 5px;
`;

const Arrow = styled.div`
  display: flex;
  height: 25px;
  width: 25px;
  background: url(${arrowDownIcon}) 50% 50% no-repeat;
  background-size: contain;
  transition: transform 0.3s;

  ${props => props.arrowUp && 'transform: rotate(180deg)'}
`;

const Title = styled.p`
  padding: 5px;
  margin: 5px 0;
`;

const List = styled.div`
  position: absolute;
  top: 40px;
  width: 220px;
  background: ${colors.main.dividers};
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0 1px 3px -1px ${colors.main.black};
`;

const ListItem = styled.p`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${colors.main.black};

  &:hover {
    background: ${colors.main.blue};
    color: ${colors.main.white};
  }
`;

const Icon = styled.span`
  display: inline-block;
  margin-right: 5px;
  width: 20px;
  height: 20px;
  ${props => `background: url(${icons[props.id]}) no-repeat;`}
  background-size: contain;
`;

export { Wrapper, Arrow, Button, Title, ListItem, List, Icon };
